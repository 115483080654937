export const FEED_POST_UPDATE_FRAGMENT = `
    fragment feedPostUpdateFragment on FeedPost {
      uid
      type
      content
      createdTime
      schemaCode
      links {
        uid
        title
        imageUrl
        description
      }
      medias {
        uid
        mediaFileResource {
          uid
          path
          schemaCode
        }
      }
    }
  `;
